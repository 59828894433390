<template>
  <div>
    <!-- Show the create form -->
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      max-width="600"
      :persistent="isMakingRequest"
    >
      <v-card :loading="isMakingRequest">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Influencer Groups
          </div>

          <!-- If there are more than one influencers -->
          <div
            v-if="influencers.length > 1"
            class="text-subtitle-2"
          >
            {{ influencers.length }} Influencers
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <!-- If there's only one influencer -->
          <template v-if="influencers.length === 1">
            <div class="d-flex align-center mb-6">
              <v-img
                height="30"
                width="30"
                max-width="30"
                class="mr-3"
                :src="platformIconMap[influencers[0].platform]"
              />

              <profile-chip
                :data="influencers[0].preview || influencers[0]"
                :platform="influencers[0].platform"
              />
            </div>
          </template>
          <!-- Otherwise -->
          <template v-else-if="influencers.length > 1">
            <v-slide-group class="mb-6">
              <v-slide-item
                v-for="influencer in influencers"
                :key="influencer.id"
              >
                <profile-chip
                  :data="influencer.preview || influencer"
                  :platform="influencer.platform"
                  class="mr-3"
                />
              </v-slide-item>
            </v-slide-group>
          </template>

          <v-autocomplete
            v-model="selectedModel"
            :items="searchItems"
            :label="computedPlatforms.length ? 'Search or create a group' : 'Search a group using name'"
            placeholder="Enter the group name"
            dense
            eager
            outlined
            :append-icon="null"
            :loading="isLoadingSearch"
            return-object
            hide-details
            item-text="name"
            item-value="id"
            no-filter
            :search-input.sync="searchInput"
            @update:search-input="handleSearch"
          >
            <template v-slot:no-data>
              <div v-if="isLoadingSearch" class="text-center py-3">
                <v-progress-circular
                  :width="3"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <div v-else class="text-center pb-2 pt-3">
                No results found.
              </div>
            </template>

            <template
              v-if="searchInput && computedPlatforms.length"
              v-slot:prepend-item
            >
              <div>
                <v-list-item
                  class="d-flex align-center py-2"
                  @click="handleCreate"
                >
                  <v-btn
                    fab
                    x-small
                    depressed
                    class="mr-3 pa-1"
                    color="primary"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>

                  <span>
                    Create "{{ searchInput.trim() }}"
                  </span>
                </v-list-item>

                <v-divider class="mt-3" />
              </div>
            </template>
          </v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowListView = true"
          >
            <v-icon left>
              format_list_numbered
            </v-icon>

            {{ isMobileDevice ? 'Select' : 'Select from list' }}
          </v-btn>

          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :disabled="isMakingRequest || !selectedModel"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the list view -->
    <v-dialog
      v-model="shouldShowListView"
      :persistent="isMakingRequest"
      max-width="400"
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text pb-4">
          Select a group
        </v-card-title>

        <v-card-text class="px-2">
          <div
            v-if="filteredListItems.length === 0"
            class="text-center pt-3"
          >
            No groups available!
          </div>

          <v-list v-else>
            <v-list-item
              v-for="item in filteredListItems"
              :key="item.id"
              two-line
              @click="handleListItemClick(item)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ formatRelativeDate(item.created_at) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowListView = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')

// Export the SFC
export default {
  // Name of the component
  name: "Search",

  // Register the components
  components: {
    ProfileChip
  },

  // Define the props
  props: {
    value: {
      type: Boolean,
      default: false
    },

    platforms: {
      type: Array,
      required: false,
      default: () => []
    },

    influencers: {
      type: Array,
      required: false,
      default: () => []
    },

    returnSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  // Define local data variables
  data: () => ({
    isMakingRequest: false,
    isLoadingSearch: false,
    isCreatingGroup: false,

    searchInput: "",
    searchItems: null,
    selectedModel: null,

    shouldShowListView: false,
    listItems: [],

    // For handling debounce while typing
    _timerId: null,

    // To cancel previous requests if a new one is made
    abortControllers: []
  }),

  // Define computable properties
  computed: {
    /**
     * Get the platforms from the influencers
     *
     * @returns {Array}
     */
    computedPlatforms() {
      return Array.from(
        new Set(
          [
            ...this.influencers.map(influencer => influencer.platform),
            ...this.platforms
          ]
        )
      )
    },

    /**
     * Get the filtered list items based on platforms
     *
     * @returns {Array}
     */
    filteredListItems() {
      return this.listItems.filter(item => this.computedPlatforms.every((platform) => item.platforms.includes(platform)))
    }
  },

  // Define the watch properties
  watch: {
    computedPlatforms: {
      deep: true,
      handler(oldValue, newValue) {
        // If they're different
        if (JSON.stringify(oldValue.sort()) !== JSON.stringify(newValue.sort())) {
          // Reset the selectedModel
          this.selectedModel = null
          this.searchInput = ""
          this.searchItems = null

          // Call the function
          this.handleSearch()
        }
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Fetch the list items
     *
     * @returns {void}
     */
    async fetchListItems() {
      try {
        // Create memoized function
        const memoized = memoize("influencer-groups-search-list", async () => {
          return await axios("/api/search/influencer-groups", {
            params: {
              per_page: 100
            }
          })
        })

        // Get the response
        const response = await memoized()

        // Set the result items
        this.listItems = response.data
      }
      catch (error) {
        logger({ type: "InfluencerGroups/Search FetchListItems", error })
      }
    },

    /**
     * Handle search request
     *
     * @returns {void}
     */
    handleSearch() {
      // Cancel pending call
      if (this._timerId) {
        clearTimeout(this._timerId)
      }

      // Set a debounce of 500ms
      this._timerId = setTimeout(async () => {
        // Cancel pending network requests
        for (const item of this.abortControllers) {
          item.controller.abort()

          // Remove the controller from the list
          this.abortControllers.splice(this.abortControllers.indexOf(item), 1)
        }

        // Define the abort controller
        const id = Symbol()
        const controller = new AbortController()

        // Push this controller to the list
        this.abortControllers.push({ id, controller })

        // Set the loading state
        this.isMakingRequest = true
        this.isLoadingSearch = true

        // Define the memoized function
        const memoized = memoize("influencer-groups-search-input", async (searchInput, platforms) => {
          console.log("Making request")
          return await axios("/api/search/influencer-groups", {
            signal: controller.signal,
            params: {
              search: searchInput,
              platforms: platforms
            }
          })
        })

        // Get the response
        const response = await memoized(this.searchInput, this.computedPlatforms.join(","))

        // Set the result items
        this.searchItems = response.data

        // Reset the loading state
        this.isMakingRequest = false
        this.isLoadingSearch = false

        // Remove the controller from cancellable array
        const index = this.abortControllers.findIndex((item) => item.id === id)
        this.abortControllers.splice(index, 1)
      }, 500)
    },

    /**
     * Handle submit request
     *
     * @returns {void}
     */
    async handleSubmit() {
      // If we're supposed to return the selected group
      if (this.returnSelected) {
        this.$emit("change", this.selectedModel)

        // Stop further execution
        return
      }

      // If a request is being made
      if (this.isMakingRequest) {
        return
      }

      // Show the loader
      this.isMakingRequest = true

      // Show a loader
      const loaderId = Symbol("InfluencerGroups/Search/handleSubmit")
      this.$store.dispatch("loaders/add", loaderId)

      // Loop through each selected item
      for (const item of this.influencers) {
        try {
          await axios({
            url: `/api/influencer-groups/${this.selectedModel.id}/influencers`,
            method: "POST",
            data: {
              platform: item.platform,
              username: item.username || item.account_id
            }
          })
        }
        catch (error) {
          logger({ type: "InfluencerGroups/Search Submit", error })
        }
      }

      // Hide the loader
      this.$store.dispatch("loaders/remove", loaderId)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: `${this.influencers.length > 1 ? 'Influencers' : 'Influencer'} added to the group!` })

      // Mark as not loading
      this.isMakingRequest = false

      // Reset the form
      this.selectedModel = null
      this.searchItems = null
      this.searchInput = ""

      // Hide the dialog
      this.shouldShowListView = false
      this.$emit('input', false)
      this.$emit('created')
    },

    /**
     * Handle create request
     *
     * @returns {void}
     */
    async handleCreate() {
      // If a request is being made
      if (this.isMakingRequest) {
        return
      }

      // Show the loader
      this.isMakingRequest = true
      this.isCreatingGroup = true

      try {
        // Get the response
        const response = await axios({
          url: "/api/influencer-groups",
          method: "POST",
          data: {
            name: this.searchInput.trim(),
            platforms: this.computedPlatforms
          }
        })

        // Refresh the user's balance
        fetchProfile()

        // Update the data
        this.isMakingRequest = false
        this.selectedModel = response.data

        // Call the submit function
        this.handleSubmit()
      }
      // Catch an error
      catch (error) {
        // Log the error
        logger({ type: "InfluencerGroups/Search/Create", error })

        // Show an error toast
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })

        // Hide the loader
        this.isMakingRequest = false
      } finally {
        // Hide the loader
        this.isCreatingGroup = false
      }
    },

    /**
     * Handle list item click
     *
     * @param {Object} item
     * @returns {void}
     */
    handleListItemClick(item) {
      // Mark the item as selected
      this.selectedModel = item

      // Call the submit function
      this.handleSubmit()
    }
  },

  /**
   * When the component is created
   *
   * @returns {void}
   */
  created() {
    // If the searchItems is null
    if (this.searchItems === null) {
      // Call the function
      this.handleSearch()
    }
  },

  /**
   * As soon as the component is rendered
   *
   * @returns {void}
   */
  mounted() {
    // Call the fetch list function
    this.fetchListItems()
  }
}
</script>

